/* eslint-disable max-lines */
import {createGlobalStyle} from 'styled-components';

import MontserratBold from 'Frontend/assets/fonts/Montserrat-Bold.woff2';
import MontserratItalic from 'Frontend/assets/fonts/Montserrat-Italic.woff2';
import MontserratLight from 'Frontend/assets/fonts/Montserrat-Light.woff2';
import MontserratMedium from 'Frontend/assets/fonts/Montserrat-Medium.woff2';
import MontserratRegular from 'Frontend/assets/fonts/Montserrat-Regular.woff2';
import MontserratSemiBold from 'Frontend/assets/fonts/Montserrat-SemiBold.woff2';
import MontserratSemiBoldItalic from 'Frontend/assets/fonts/Montserrat-SemiBoldItalic.woff2';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

export const theme = {
    colors: {
        darkBorder: '#8E8E99',
        error: '#FB4032',
        green: '#DFEAE1',
        interview: '#EFEBE0',
        light100: '#ffffff',
        lightBorder: '#C8C8D0',
        lighterBorder: '#E9E9EC',
        link: '#D56B50',
        primary: '#0070f3',
        progressBack: '#C0B2AA',
        progressFront: '#D56B50',
        red: '#FAEFED',
        textColor: '#2E3542',
        textHighlight: '#DD8871',
        yellow: '#FFF9EC'
    }
};

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Bold'), local('Montserrat-Bold'),
            url(${MontserratBold}) format('woff2');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
            url('Montserrat-ExtraBold.woff2') format('woff2');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Black'), local('Montserrat-Black'),
            url('Montserrat-Black.woff2') format('woff2');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
            url('Montserrat-BoldItalic.woff2') format('woff2');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
            url('Montserrat-BlackItalic.woff2') format('woff2');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
            url('Montserrat-ExtraLight.woff2') format('woff2');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
            url('Montserrat-ExtraLightItalic.woff2') format('woff2');
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
            url('Montserrat-ExtraBoldItalic.woff2') format('woff2');
        font-weight: 800;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Italic'), local('Montserrat-Italic'),
            url(${MontserratItalic}) format('woff2');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
            url('Montserrat-MediumItalic.woff2') format('woff2');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Light'), local('Montserrat-Light'),
            url(${MontserratLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Medium'), local('Montserrat-Medium'),
            url(${MontserratMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Regular'), local('Montserrat-Regular'),
            url(${MontserratRegular}) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
            url('Montserrat-LightItalic.woff2') format('woff2');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
            url('Montserrat-ThinItalic.woff2') format('woff2');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
            url(${MontserratSemiBold}) format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat Thin'), local('Montserrat-Thin'),
            url('Montserrat-Thin.woff2') format('woff2');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
            url(${MontserratSemiBoldItalic}) format('woff2');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }

    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    html, body {
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
        height: 100%;
    }

    #__next {
        height: 100%;
    }

    &:target {
        display: block;
        height: ${clampCss(66, 'm')};
        margin-top: ${clampCss(-66, 'm', true)};
        position: absolute;
        top: 0;

        ${MEDIA.TABLET.UP} {
            height: max(97px, ${pxToVw(132, 'd')});
            margin-top: max(${pxToVw(-132, 'd')}, -132px);
        }
    }
`;