export const MEDIA = {
    DESKTOP: {UP: '@media (min-width: 1380px)'},
    MOBILE: {DOWN: '@media (max-width: 800px)'},
    TABLET: {
        DOWN: '@media (max-width:1379.95px)',
        UP: '@media (min-width:800px)'
    }
};

export const MEDIA_JS = {
    DESKTOP: {UP: '(min-width: 1380px)'},
    MOBILE: {DOWN: '(max-width: 800px)'},
    TABLET: {
        DOWN: '(max-width:1379.95px)',
        UP: '(min-width:800px)'
    }
};