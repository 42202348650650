/* eslint-disable react/jsx-filename-extension */
import '@risingstack/react-easy-state';

import React, {Component} from 'react';

import Config from 'next/config';
import Head from 'next/head';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';

import {GlobalStyle, theme} from 'Frontend/utils/globalStyles';

/**
 * App Component.
 *
 * @export
 * @class App
 * @extends {Component}
 */
export default class App extends Component {
    static propTypes = {
        Component: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func
        ]).isRequired,
        pageProps: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired
    }

    /**
     * Renders the component.
     *
     * @returns {Component} React Component.
     * @memberof App
     */
    render() {
        const {Component: PageComponent, pageProps, router} = this.props;
        const getLayout = PageComponent.getLayout
            || ((routerFallback, pagePropsFallback, PageFallBackComponent) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PageFallBackComponent router={routerFallback} {...pagePropsFallback} />
            ));
        const {publicRuntimeConfig: {googleApi} = {}} = Config();

        return (
            <>
                <GlobalStyle />
                <Head>
                    <script data-settings-id="08TeZ3Pax" id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/bundle.js" defer />
                    <script
                        key="GoogleMapsApi"
                        src={`https://maps.googleapis.com/maps/api/js?key=${googleApi}&libraries=places`}
                        type="text/javascript"
                        defer
                    />
                </Head>
                <ThemeProvider theme={theme}>
                    {getLayout(router, pageProps, PageComponent)}
                </ThemeProvider>
            </>
        );
    }
}