import React from 'react';

import ReactDOMServer from 'react-dom/server';

/**
 * Parses an svg icon as url image.
 *
 * @param {ReactElement} Icon  An React svg Icon component.
 * @param {Object}       props The Icon props.
 *
 * @returns {String} An html string.
 */
export const iconAsBackground = (Icon, props) => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
)}`;

/**
 * Parses an svg icon as url image.
 *
 * @param {ReactElement} Icon  An React svg Icon component.
 * @param {Object}       props The Icon props.
 *
 * @returns {String} An html string.
 */
export const iconWithGradientAsBackground = (Icon, props) => {
    let uniBtoa;

    if (process.browser) {
        uniBtoa = btoa;
    } else {
        uniBtoa = require('btoa');
    }

    return (
        `data:image/svg+xml;base64,${uniBtoa(
            // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
            ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
        )}`
    );
};

/**
 * Calculates the corresponding vw from pixel values in awareness of the device it is used on.
 *
 * @param {Number} px     The pixel size as number.
 * @param {String} device The device to calculate for (desktop = d|mobile = m|tablet = t).
 *
 * @returns {String} The corresponding vw value.
 */
export const pxToVw = (px, device) => {
    const perc = 100;
    const devices = {
        d: '1920',
        m: '375',
        t: '900'
    };

    if (devices[String(device)]) {
        return `${(perc / devices[String(device)]) * px}vw`;
    }

    throw new Error('No such device defined!');
};

/**
 * Sets the clamped values for mobile.
 *
 * @param {Number}  px              The pixel size as number.
 * @param {String}  device          The device to calculate for (desktop = d|mobile = m|tablet = t).
 * @param {Boolean} [reverse=false] Reverse clamp for negative numbers.
 *
 * @returns {String} The corresponding clamp.
 */
export const clampCss = (px, device, reverse = false) => {
    const percFaktor = 1.6666;

    if (reverse) {
        return `clamp(${percFaktor * px}px, ${pxToVw(px, device)}, ${px}px)`;
    }

    return `clamp(${px}px, ${pxToVw(px, device)}, ${percFaktor * px}px)`;
};

/**
 * Parses the Answers array to an usable Object.
 *
 * @param {Array} answers An array of Answer objects.
 *
 * @returns {Object} All answers in one object.
 */
export const parseAnswersToObject = answers => answers.reduce((acc, val) => {
    if (typeof val === 'object' && val !== null) {
        return {
            ...acc,
            ...val
        };
    }

    return acc;
}, {});


/**
 * Calculate the stars for reviews and google rating.
 * @returns {Object} rating and stars
 */
export const getStars = ({googleRating = 0, reviewCount = 0, reviews = []}) => {
    let rating;
    const STAR_MAX = 5;
    const PERCENT = 100;
    const stars = new Array(STAR_MAX);

    const fullRating = reviews.reduce((acc, val) => acc + val.rating, 0);

    if (reviewCount > 0) {
        rating = fullRating / reviewCount;

        // Only consider valid Google rating (starts at 1 star)
        if (googleRating > 0) {
            // eslint-disable-next-line @nfq/no-magic-numbers
            rating = (rating + googleRating) / 2;
        }
    } else {
        rating = googleRating;
    }

    const fullStars = Math.trunc(rating);
    const percent = `${((rating - fullStars) * PERCENT).toFixed(0)}%`;

    stars.fill('100%', 0, fullStars);
    stars.fill(percent, fullStars, fullStars + 1);
    stars.fill('0%', fullStars + 1, STAR_MAX);

    return {
        rating,
        stars
    };
};

/**
 * Waits x ms till resolve.
 *
 * @param {Number} ms The time to wait in milliseconds.
 *
 * @returns {Promise} An promise that resolves after x ms.
 */
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));